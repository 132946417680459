.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  
    color: #e5b507;
    width: 100%;
    height: 100vh;
    background: #0c0c0c;
  }

.error-page h2 {
    padding-top: 16px;
    font-size: 32px;
    color: #DDDBBC;
  }
  
.error-page a {
    color: #5d5832;
    text-decoration: none;
  }
  
.error-page a:hover {
    color: #ffc107;
    text-decoration: none;
  }
  
hr {
    width: 290px;
    border: none;
    border-top: 1px solid #DDDBBC;
    margin: 4px 0 16px 0;
  }